.animated {
  -webkit-animation-duration: 650ms;
  animation-duration: 650ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated_infinite {
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.animation_reverse {
  animation-direction: reverse;
}

.animation_delay_200 {
  animation-delay: 200ms;
}


.animation_delay_120 {
  animation-delay: 120ms;
}

@keyframes float {
	0% {
		box-shadow: 0 2px 6px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 10px 6px 0px rgba(0,0,0,0.2);
		transform: translatey(-2px);
	}
	100% {
		box-shadow: 0 2px 6px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@-webkit-keyframes float {
	0% {
		box-shadow: 0 1px 3px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 5px 3px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 1px 3px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@keyframes fade_in {
  0% {
    opacity: 0;
 }
 100% {
    opacity: 1;
 }
}

@keyframes fade_in_horizontal {
  0% {
     opacity: 0;
     transform: translateX(20px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
} 

@-webkit-keyframes fade_in_horizontal {
  0% {
     opacity: 0;
     -webkit-transform: translateX(20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(0);
  }
}

@keyframes fade_in_vertical {
  0% {
     opacity: 0;
     transform: translateY(20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
} 

@-webkit-keyframes fade_in_vertical {
  0% {
     opacity: 0;
     -webkit-transform: translateY(20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}

.fade_in_horizontal {
  -webkit-animation-name: fade_in_horizontal;
  animation-name: fade_in_horizontal;
}

.fade_in_vertical {
  -webkit-animation-name: fade_in_vertical;
  animation-name: fade_in_vertical;
}

.float {
  -webkit-animation-name: float ;
  animation-name: float;
}

.transition_100 {
  transition: 200ms ease-in-out;
}
.transition_200 {
  transition: 200ms ease-in-out;
}