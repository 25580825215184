@mixin gridStyling {
	display: grid;
	gap: 1.1rem;

	@media screen and (max-width: 680px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	@media screen and (min-width: 680px) and (max-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media screen and (min-width: 768px) and (max-width: 1000px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@media screen and (min-width: 1000px) and (max-width: 1320px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media screen and (min-width: 1320px) and (max-width: 1700px) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	@media screen and (min-width: 1700px) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

.mywheels-model_img-title {
	color: #808080;
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 1.2rem;
}

.mywheels-model_img_cont {
	@include gridStyling;
	grid: 2rem;

	.mywheels-model_img-item {
		border: 1px solid #d8d8d8;
		border-radius: 0.4rem;
		aspect-ratio: 1/1;
		position: relative;
		cursor: pointer;

		.mywheels-model_img_actions-cont {
			transition: 250ms ease-in-out;
			position: absolute;
			right: 5px;
			top: 7px;
			z-index: 1;
			padding: 0.3rem;
			border-radius: 0.2rem;
			background: rgba(0, 0, 0, 0.3);
			display: flex;

			> :not(:first-of-type) {
				margin-left: 5px;
			}

			> .mywheels-model_img_action-icon,
			.mywheels-model_img_action-icon-hover {
				padding: 0.1rem 0.3rem;
				aspect-ratio: 1/1;
			}

			> .mywheels-model_img_action-text {
				padding: 0.2rem 0.6rem;

				> span {
					font-size: 12px;
				}
			}

			> .mywheels-model_img_action {
				border-radius: 0.2rem;
				cursor: pointer;

				svg {
					color: white;
					width: 15px;
					height: 15px;
				}
			}
		}

		.mywheels-model_img-item-content_cont {
			position: relative;
			overflow: hidden;
			width: 100%;
			aspect-ratio: 1;

			:is(img, .mywheels-model_img_svg-cont) {
				height: 100%;
				width: inherit;
			}

			> img {
				border-radius: 0.4rem;
				object-fit: cover;
				object-position: center;
			}

			.mywheels-model_img_svg-cont {
				display: grid;
				place-items: center;

				> svg {
					width: 6rem;
					height: 6rem;
				}
			}
		}
	}
}
