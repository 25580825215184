@mixin gridStyling {
	display: grid;
	gap: 1.1rem;

	@media screen and (max-width: 680px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	@media screen and (min-width: 680px) and (max-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media screen and (min-width: 768px) and (max-width: 1000px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@media screen and (min-width: 1000px) and (max-width: 1320px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media screen and (min-width: 1320px) and (max-width: 1700px) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	@media screen and (min-width: 1700px) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

.mywheels-drive_cont {
	.mywheels-drive-title {
		color: #808080;
		font-weight: 500;
		font-size: 1rem;
		margin-bottom: 1.2rem;
	}

	.mywheels-drive-files_cont {
		@include gridStyling;

		.mywheels-drive_files-item {
			border: 1px solid #d8d8d8;
			border-radius: 0.4rem;
			aspect-ratio: 1/1;
			position: relative;
			cursor: pointer;

			.mywheels-drive_files_actions-cont {
				transition: 250ms ease-in-out;
				position: absolute;
				right: 5px;
				top: 7px;
				z-index: 1;
				padding: 0.3rem;
				border-radius: 0.2rem;
				background: rgba(0, 0, 0, 0.3);
				display: flex;

				> :not(:first-of-type) {
					margin-left: 5px;
				}

				> .mywheels-drive_files_action-icon,
				.mywheels-drive_files_action-icon-hover {
					padding: 0.1rem 0.3rem;
					aspect-ratio: 1/1;
				}

				> .mywheels-drive_files_action-text {
					padding: 0.2rem 0.6rem;

					> span {
						font-size: 12px;
					}
				}

				> .mywheels-drive_files_action {
					border-radius: 0.2rem;
					cursor: pointer;

					svg {
						color: white;
						width: 15px;
						height: 15px;
					}
				}
			}

			.mywheels-drive_files-item-content_cont {
				position: relative;
				overflow: hidden;
				width: 100%;
				aspect-ratio: 1;
				display: grid;
				place-items: center;

				:is(img, .mywheels-drive_files_svg-cont) {
					height: 100%;
					width: inherit;
				}

				>video {
					border-top-left-radius: 0.4rem;
					border-top-right-radius: 0.4rem;
					object-fit: cover;
					object-position: center;
					width: 100%;
				}

				> img {
					border-top-left-radius: 0.4rem;
					border-top-right-radius: 0.4rem;
					object-fit: cover;
					object-position: center;
				}

				.mywheels-drive_files_svg-cont {
					display: grid;
					place-items: center;

					> svg {
						width: 6rem;
						height: 6rem;
					}
				}
			}

			.mywheels-drive-details {
				height: 30px;
				padding: 1.1rem 0.5rem;
				display: flex;
				align-items: center;
				border-top: 1.2px solid #d8d8d8;

				> svg {
					width: 1rem;
					height: 1rem;
					margin-right: 0.4rem;
				}
				> span {
					width: 80%;
					color: #808080;
					font-size: 0.8rem;
					font-weight: 500;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.mywheels-drive-path_cont {
	color: #808080;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media screen and (max-width: 576px) {
		justify-content: center;
		margin-bottom: 12px;
	}

	.mywheels-drive-path {
		border-radius: 0.4rem;
		color: #808080;
		cursor: pointer;
		font-size: 1.1rem;
		transition: 300ms;
		margin: 0rem 0.1rem;
		padding: 0.3rem 0.5rem;
		display: flex;
		align-items: center;

		&:hover {
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			text-transform: none;
		}
		> svg {
			width: 18px;
			height: 18px;
			margin-right: 7px;
		}
	}
	.mywheels-drive-path-selected {
		color: #545cd8;
	}
}

.mywheels-drive-table_icon {
	> svg {
		width: 1.5rem;
		height: 1.5rem;
	}
}

@media (hover: hover) {
	.mywheels-drive_files-item {
		&:hover {
			.mywheels-drive_files_actions-cont {
				transform: translateY(0%);
			}
		}
	}

	.mywheels-drive_files_actions-cont {
		transform: translateY(-120%);
	}

	.mywheels-drive_files_action-icon-hover {
		display: none;
	}

	.mywheels-drive_folder-item-pop-over-action-hover {
		display: none;
	}

	.mywheels-drive_folder-item-menu-icon-hover {
		display: block;
	}
}
