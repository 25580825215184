.mana_file-input{
  display: grid;
  place-items: center;
  text-align: center;
  background-color: lightgray;
  border-radius: 0.4em;
  
  .mana_file-input-empty-div {
    padding: 1.5em 1em;
    width: 100%;
    cursor: pointer;  

    .mana_file-input-bg-svg {
      width: 100px;
      height: 100px;
      color: gray;
      margin-bottom: 0.5em;
    }
    .mana_file-input-help-text {
      display: block;
      font-weight: 500;
      color: grey;
    }
  }

  .mana_file-input-filled-div {
    border-radius: 0.4em;
    position: relative;
    width: 100%;
    background-color: black;

    .mana_file-input-img {
      max-width: 300px;
    }

    .mana_file-input-remove-btn {
      padding: 0.2em 0.4em;
      background-color: #ee6d4d;
      border-radius: 0.8em;
      position: absolute;
      color: white;
      width: 35px;
      height: 35px;
      right: 1em;
      top: 1em;
      cursor: pointer;
    }
  }
}