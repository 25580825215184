.china-press-dialog-full_screen {
	margin: 0px !important;
	width: 100vw !important;
	max-width: 100vw !important;
}

.china-press-dialog {
	box-shadow: none !important;

	.modal-header {
		height: 60px;
	}
	.modal-footer {
		height: 65px;
	}
}

.china-press-dialog-content-full-screen {
	overflow-y: auto;
	height: calc(100vh - 125px);
}

.china-press-dialog-content {
	max-height: 78vh;
	overflow-y: auto;
}
